 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                this.locationsFilter.includes(tab.location) ||
                !this.locationsFilter.length
            )"
            :key="index"
          >
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab-item
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                this.locationsFilter.includes(tab.location) ||
                !this.locationsFilter.length
            )"
            :key="index"
          >
            <v-data-table
              :headers="headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  label="To Order"
                  single-line
                  type="number"
                  v-model="item.quantity"
                ></v-text-field>
              </template>
              <template v-slot:item.item="{ item }">
                <router-link
                  :to="{
                    path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                  }"
                  class="link"
                  >{{ item.item.displayname || item.item.name }}</router-link
                >
              </template>

              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="locations"
                        v-model="location"
                        label="Location"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                        hide-details
                        multiple
                        clearable
                        @change="getData()"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <exportData :headers="headers" :items="items" />
                    </v-col>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-switch
                              v-model="showAll"
                              dense
                              class="mt-0 mb-0"
                              label="Show All"
                            >
                            </v-switch>
                          </div>
                        </template>
                        <span
                          >Monkfish Automotive LTD from Kidderminster
                          Warehouse</span
                        >
                      </v-tooltip>
                    </v-col>
                    <v-col>
                      <v-btn @click="getData()" block tile color="primary"
                        >Refresh</v-btn
                      >
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-divider></v-divider>
                      <toolfooter
                        :items="items"
                        :selected="selected"
                        field="quantity"
                        v-if="!loading"
                      />
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import footer from "./footer";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "warehouse" },
        { text: "Item", value: "item", sortable: false },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Pref. Vendor", value: "customer" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "To Order", value: "quantity" },
        { text: "Available", value: "quantityavailable" },
        { text: "Backorder", value: "quantitybackorder" },
        { text: "On Order", value: "quantityonorder" },
        { text: "Stock Group", value: "stockgroup" },
        { text: "Stock Category", value: "stockcategory" },
      ],
      items: [],
      showAll: false,
      rows: [100],
      singleSelect: false,
      selected: [],
      locations: [],
      location: [],
      search: "",
      show: false,
      tabs: {},
      locationsFilter: [],
    };
  },
  async created() {
    this.loading = true;
    this.locations = await service("accounting", {
      action: "find",
      data: [
        {
          recordtype: "location",
          hidden: { $ne: true },
          inactive: { $ne: true },
        },
      ],
    });
    this.tabs = {
      PL: {
        name: "Warehouse PL",
        location: "5e0dbaba9e33df43f0b3a495",
        items: [],
        headers: this.headers,
      },
      UK: {
        name: "Warehouse UK (Birmingham)",
        location: "5faa7485371aa739b8a08843",
        items: [],
        headers: this.headers,
      },
      PeddersUK: {
        name: "Warehouse UK",
        location: "61e14c6ae4a35a668517a8fe",
        items: [],
        headers: this.headers,
      },
      BrakesPL: {
        name: "Brakes PL",
        location: "62de6196fa2fc522dc692c7e",
        items: [],
        headers: this.headers,
      },
      Monkfish: {
        name: "Monkfish",
        location: "61e14c6ae4a35a668517a8fe",
        items: [],
        headers: this.headers,
      },
    };
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      this.loading = true;
      this.tabs["PL"].items = [];
      this.tabs["UK"].items = [];
      this.tabs["PeddersUK"].items = [];
      this.tabs["BrakesPL"].items = [];
      this.tabs["Monkfish"].items = [];
      let result = await service(
        "orders",
        {
          action: "getBackorderItems",
          location: this.location,
        },
        false,
        true
      );
      this.itemsWithoutMonkfish = [];
      this.items = result.items;
      this.items.forEach((item) => {
        item.quantity = item.quantitybackorder - item.quantityonorder;
        if (
          !(
            item.manufacturer == "Monkfish Automotive LTD" &&
            ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
              item.location
            )
          )
        ) {
          if (item.company && ["maincompany"].includes(item.company)) {
            this.tabs["PL"].items.push(item);
          }
          if (
            item.company &&
            ["ozpartsuklimited"].includes(item.company) &&
            item.location &&
            !["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
              item.location
            )
          ) {
            this.tabs["UK"].items.push(item);
          }
          if (
            item.location &&
            ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
              item.location
            )
          ) {
            this.tabs["PeddersUK"].items.push(item);
          }
          if (item.company && ["brakespl"].includes(item.company)) {
            this.tabs["BrakesPL"].items.push(item);
          }
        } else this.tabs["Monkfish"].items.push(item);
      });
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    toolfooter: footer,
    exportData: exportData,
  },
};
</script>