 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          loading-text="Loading... Please wait"
          :loading="loading"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.delivery="{ item }">
            <router-link
              :to="{
                path: `/${item.delivery.collection}/${item.delivery.recordtype}/view/${item.delivery._id}`,
              }"
              class="link"
              >{{
                item.delivery.displayname || item.delivery.name
              }}</router-link
            >
          </template>
          <template v-slot:item.childs="{ item }">
            <router-link
              v-for="po in item.childs"
              :key="po._id"
              :to="{
                path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
              }"
              class="link"
              >{{ po.displayname || po.name }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn
                    @click="action"
                    block
                    tile
                    color="primary"
                    :disabled="
                      loading ||
                      !selected.length ||
                      $route.params.mode == 'edit'
                    "
                    >Receive Selected</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    :disabled="loading"
                    >Refresh</v-btn
                  >
                </v-col>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="vendors"
                        v-model="vendor"
                        label="Vendor"
                        item-text="displayname"
                        item-value="_id"
                        return-object
                        outlined
                        dense
                      ></v-select>
                      <v-select
                        :items="deliverymethods"
                        v-model="deliverymethod"
                        label="Delivery Method"
                        item-text="displayname"
                        item-value="_id"
                        return-object
                        outlined
                        dense
                      ></v-select>
                    </v-col>

                    <v-col>
                      <v-textarea outlined label="Message" v-model="message"></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>-->
            </v-container>
          </template>
          <template slot="body.append">
            <tr>
              <th>Total:</th>
              <th v-for="(col, index) in headers" :key="index">
                {{ calcTotal(col.value) }}
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import saveRecord from "../../../common/methods/saveRecord";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Bill", value: "transaction", sortable: false },
        { text: "Exchange Rate", value: "exchangerate", sortable: false },
        { text: "Vendor", value: "customer", sortable: false },
        { text: "Delivery", value: "delivery", sortable: false },
        { text: "Delivery Method", value: "deliverymethod", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Received", value: "quantityreceived", sortable: false },
        { text: "Received Amt.", value: "receivedamount", sortable: false },
        { text: "Memo", value: "memo", sortable: false },
        { text: "PZ", value: "childs", sortable: false },
      ],
      items: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      vendor: null,
      vendors: [],
      message: "",
      delivery: this.record,
    };
  },
  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "delivery",
        {
          action: "getDeliveryReceived",
          delivery: this.delivery,
        },
        false,
        true
      );
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    calcTotal(key) {
      if (this.items.length > 0) {
        let sum = this.items.reduce((a, b) => {
          if (
            !isNaN(parseFloat(a[key] || a)) &&
            !isNaN(parseFloat(b[key] || b))
          ) {
            return parseFloat(a[key] || a || 0) + parseFloat(b[key] || b || 0);
          } else return parseFloat(a || 0);
        }, 0);

        return sum && !isNaN(parseInt(sum)) ? sum.toFixed(2) : "";
      }
    },
    async action() {
      this.loading = true;
      window.onbeforeunload = function () {
        return "Are you sure you want to close the window?";
      };
      for (let bill of this.selected) {
        try {
          if (bill.quantity > bill.quantityreceived) {
            let status = await service(
              "transactions",
              {
                action: "newTransaction",
                delivery: this.record,
                record: {
                  recordtype: "itemreceipt",
                  collection: "transactions",
                  created: "new",
                  createdfrom: [bill._id],
                },
              },
              false,
              true
            );
            if (status) bill.childs.push(status);
          }
        } catch (error) {
          alert(error.message);
          this.loading = false;
          return error;
        }
      }
      //await this.getData();
      this.loading = false;
      saveRecord(false, this.record, "saveAndEmail", "deliveries", 1);
    },
  },
};
</script>