var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-tab',{key:1},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v("PL")]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.itemsPL.length))])])],1),_c('v-tab',{key:2},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v("UK")]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.itemsUK.length))])])],1),_c('v-tab',{key:3},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v("IC")]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.itemsIC.length))])])],1),_c('v-tab-item',{key:1},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsPL,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.itemsPL}})],1),_c('v-spacer'),_c('v-col',[_c('v-switch',{attrs:{"label":"Show All"},model:{value:(_vm.showall),callback:function ($$v) {_vm.showall=$$v},expression:"showall"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date from","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},on))]}}]),model:{value:(_vm.menufrom[_vm.active]),callback:function ($$v) {_vm.$set(_vm.menufrom, _vm.active, $$v)},expression:"menufrom[active]"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1)],1)],1)]},proxy:true},{key:"item.memo",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"type":"text","dense":"","hide-details":""},on:{"click:clear":function($event){return _vm.action(item)},"change":function($event){return _vm.action(item)}},model:{value:(item.memo),callback:function ($$v) {_vm.$set(item, "memo", $$v)},expression:"item.memo"}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.planneddateofshipment",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planneddateofshipment ? new Date(item.planneddateofshipment) .toISOString() .substr(0, 10) : ""))])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.file",fn:function(ref){
                var item = ref.item;
return [(item.file)?_c('a',{key:item.file._id,staticClass:"link d-block",attrs:{"href":item.file.url,"target":"_blank"}},[_vm._v(_vm._s(item.file.displayname || item.file.name))]):_vm._e()]}},{key:"item.file2",fn:function(ref){
                var item = ref.item;
return [(item.file2)?_c('a',{key:item.file2._id,staticClass:"link d-block",attrs:{"href":item.file2.url,"target":"_blank"}},[_vm._v(_vm._s(item.file2.displayname || item.file2.name))]):_vm._e()]}},{key:"item.files",fn:function(ref){
                var item = ref.item;
return _vm._l((item.files),function(item){return _c('a',{key:item._id,staticClass:"link d-block",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.displayname || item.name))])})}},{key:"item.transaction",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.altpdfform",fn:function(ref){
                var item = ref.item;
return [(item.altpdfform)?_c('a',{staticClass:"link",attrs:{"href":("http://localhost:8080/api/pdf/" + (item.transaction.collection) + "/" + (item.transaction._id) + "/?pdfform=" + (item.altpdfform._id)),"target":"_blank"}},[_vm._v(_vm._s(item.altpdfform.displayname || item.altpdfform.name))]):_vm._e()]}},{key:"item.accountonhold",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}}])})],1),_c('v-tab-item',{key:2},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsUK,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.itemsUK}})],1),_c('v-spacer'),_c('v-col',[_c('v-switch',{attrs:{"label":"Show All"},model:{value:(_vm.showall),callback:function ($$v) {_vm.showall=$$v},expression:"showall"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date from","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},on))]}}]),model:{value:(_vm.menufrom[_vm.active]),callback:function ($$v) {_vm.$set(_vm.menufrom, _vm.active, $$v)},expression:"menufrom[active]"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1)],1)],1)]},proxy:true},{key:"item.memo",fn:function(ref){
                var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"type":"text","dense":"","hide-details":""},on:{"click:clear":function($event){return _vm.action(item)},"change":function($event){return _vm.action(item)}},model:{value:(item.memo),callback:function ($$v) {_vm.$set(item, "memo", $$v)},expression:"item.memo"}})]}},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.planneddateofshipment",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planneddateofshipment ? new Date(item.planneddateofshipment) .toISOString() .substr(0, 10) : ""))])]}},{key:"item.customer",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.file",fn:function(ref){
                var item = ref.item;
return [(item.file)?_c('a',{key:item.file._id,staticClass:"link d-block",attrs:{"href":item.file.url,"target":"_blank"}},[_vm._v(_vm._s(item.file.displayname || item.file.name))]):_vm._e()]}},{key:"item.file2",fn:function(ref){
                var item = ref.item;
return [(item.file2)?_c('a',{key:item.file2._id,staticClass:"link d-block",attrs:{"href":item.file2.url,"target":"_blank"}},[_vm._v(_vm._s(item.file2.displayname || item.file2.name))]):_vm._e()]}},{key:"item.files",fn:function(ref){
                var item = ref.item;
return _vm._l((item.files),function(item){return _c('a',{key:item._id,staticClass:"link d-block",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.displayname || item.name))])})}},{key:"item.transaction",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.altpdfform",fn:function(ref){
                var item = ref.item;
return [(item.altpdfform)?_c('a',{staticClass:"link",attrs:{"href":("http://localhost:8080/api/pdf/" + (item.transaction.collection) + "/" + (item.transaction._id) + "/?pdfform=" + (item.altpdfform._id)),"target":"_blank"}},[_vm._v(_vm._s(item.altpdfform.displayname || item.altpdfform.name))]):_vm._e()]}},{key:"item.accountonhold",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}}])})],1),_c('v-tab-item',{key:3},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsIC,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.itemsIC}})],1),_c('v-spacer'),_c('v-col',[_c('v-switch',{attrs:{"label":"Show All"},model:{value:(_vm.showall),callback:function ($$v) {_vm.showall=$$v},expression:"showall"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date from","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},on))]}}]),model:{value:(_vm.menufrom[_vm.active]),callback:function ($$v) {_vm.$set(_vm.menufrom, _vm.active, $$v)},expression:"menufrom[active]"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1)],1)],1)]},proxy:true},{key:"item.memo",fn:function(ref){
                var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"type":"text","dense":"","hide-details":""},on:{"click:clear":function($event){return _vm.action(item)},"change":function($event){return _vm.action(item)}},model:{value:(item.memo),callback:function ($$v) {_vm.$set(item, "memo", $$v)},expression:"item.memo"}})]}},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.planneddateofshipment",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planneddateofshipment ? new Date(item.planneddateofshipment) .toISOString() .substr(0, 10) : ""))])]}},{key:"item.customer",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.file",fn:function(ref){
                var item = ref.item;
return [(item.file)?_c('a',{key:item.file._id,staticClass:"link d-block",attrs:{"href":item.file.url,"target":"_blank"}},[_vm._v(_vm._s(item.file.displayname || item.file.name))]):_vm._e()]}},{key:"item.file2",fn:function(ref){
                var item = ref.item;
return [(item.file2)?_c('a',{key:item.file2._id,staticClass:"link d-block",attrs:{"href":item.file2.url,"target":"_blank"}},[_vm._v(_vm._s(item.file2.displayname || item.file2.name))]):_vm._e()]}},{key:"item.files",fn:function(ref){
                var item = ref.item;
return _vm._l((item.files),function(item){return _c('a',{key:item._id,staticClass:"link d-block",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.displayname || item.name))])})}},{key:"item.transaction",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.altpdfform",fn:function(ref){
                var item = ref.item;
return [(item.altpdfform)?_c('a',{staticClass:"link",attrs:{"href":("http://localhost:8080/api/pdf/" + (item.transaction.collection) + "/" + (item.transaction._id) + "/?pdfform=" + (item.altpdfform._id)),"target":"_blank"}},[_vm._v(_vm._s(item.altpdfform.displayname || item.altpdfform.name))]):_vm._e()]}},{key:"item.accountonhold",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }