import BSON from 'bson';
import store from '../store/index'
import { variables } from '../config/variables'
/* eslint-disable */
export default async (collection, data, type, server2) => {

  let headers = {
    Authorization: `Bearer ${store.state.user.token}`,
  };
  //data.location = store.state.user.location ? store.state.user.location : data.location;
  //data.forlocations = store.state.user.forlocations ? store.state.user.forlocations : data.forlocations;
  let body = data;

  data.source = "3cerp";
  if (type == "file") {
    headers = { Authorization: `Bearer ${store.state.user.token || ""}` };
    body = data;
  } else {
    if (type == "json") {
      headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.state.user.token || ""}`,
        User: store.state.user._id,
      };
      body = JSON.stringify(data);

    } else {
      headers = {
        // 'Accept': 'application/octet-stream',
        // 'Content-Type': 'application/octet-stream',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.state.user.token || ""}`,
        User: store.state.user._id
      };
      body = JSON.stringify(data);
      //body = BSON.serialize(data);
    }
  }


  return fetch((server2 ? (data && ['recalcRecord', 'loadRecord'].includes(data.action) ? variables.apiurl3 : variables.apiurl2) : variables.apiurl) + '/core/' + collection, {
    method: 'POST',
    headers: headers,
    body: body,
  }).then(async response => {
    let responseData = {};
    if (response.headers.get('content-type') === 'application/octet-stream') {
      let buffer = await response.arrayBuffer();
      buffer = new Uint8Array(buffer);
      responseData = BSON.deserialize(buffer);
      //let transactionlines = responseData["transactionlines"];
      //delete responseData["transactionlines"];
      //responseData["transactionlines"] = transactionlines;
      //console.log(transactionlines)
      // if (transactionlines) {
      //   responseData["transactionlines"] = [];
      //   transactionlines.forEach(line => {
      //     //console.log(line)
      //     responseData["transactionlines"].push(line)
      //   })
      // }
    } else {
      if (response.headers.get('content-type') === 'application/pdf;charset=UTF-8')
        responseData = response.blob();
      else responseData = await response.json();
    }
    if (response.ok) {
      return responseData;
    } else {
      console.log('nieok');
      //this.$store.state.globalNotifications.push(responseData)
      throw responseData;
    }
  });
};
/* eslint-enable */
