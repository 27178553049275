<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
    :readonly="
      ($route.params.mode == 'view' ||
        this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        record.archived) &&
      !this.formSettings.show
    "
    :disabled="
      ($route.params.mode == 'view' ||
        this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        record.archived) &&
      !this.formSettings.show
    "
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        placeholder
        v-model="date"
        append-icon="event"
        :rules="[rules.required]"
        readonly
        v-on="on"
        outlined
        dense
        clearable
        @click:clear="emitChangedValue(true)"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="emitChangedValue()"
      :first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>

<script>
/* eslint-disable */

export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      date: this.value,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      menu: false,
      modal: false,
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
      },
    };
  },
  methods: {
    emitChangedValue(clear) {
      this.$emit("emit", {
        field: this.field,
        value:
          this.date && !clear
            ? new Date(new Date(this.date).toISOString().substr(0, 10))
            : null,
      });
    },
  },
  created() {
    this.date = this.value
      ? new Date(this.value).toISOString().substr(0, 10)
      : null;
  },
  watch: {
    date(value) {
      //console.log(value)
      //if(!value) this.emitChangedValue();
    },
    record: {
      handler: async function (after, before) {
        if (this.formSettings.show) this.field = "value";
        if (
          (this.record[this.field] || this.record.value) != new Date(this.date)
        ) {
          this.date =
            this.record[this.field] || this.record.value
              ? new Date(this.record[this.field] || this.record.value)
                  .toISOString()
                  .substr(0, 10)
              : null;
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.v-menu__content .v-date-picker-title {
  height: 70px;
}
.v-menu__content .v-picker {
  border-radius: 0 !important;
  box-shadow: none;
}
</style>