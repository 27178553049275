<template>
  <v-container fluid>
    <v-textarea
      label="Paste from excel"
      placeholder
      v-model="pasteData"
      outlined
      dense
    ></v-textarea>

    <v-row>
      <v-col>
        <v-select
          dense
          v-model="queryid"
          :items="queries"
          label="Queries"
          outlined
          item-text="name"
          item-value="_id"
          hide-details
          clearable
        ></v-select>
      </v-col>
      <v-col>
        <v-dialog
          v-model="open"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Open Query
            </v-btn>
          </template>
          <v-card style="height: 100%">
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="open = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Import</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="importCustomers()"> Import </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <queryresult
              v-if="queryid"
              used="campaign"
              :queryid="queryid"
              :filters="filters"
              @emit="catchEmit($event)"
            />
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "../../../api/index";
export default {
  props: ["record"],
  data() {
    return {
      open: false,
      queries: [],
      queryid: null,
      filters: {},
      pasteData: "",
    };
  },
  async created() {
    await this.getQueries();
  },
  watch: {
    async "record.type"() {
      await this.getQueries();
    },
    async pasteData(value) {
      if (value) {
        let data = value.split("\n");
        data.forEach((row) => {
          let values = row.split("\t");
          this.record["campaignreceivers"].push({
            customer: values[0],
            receiverto: values[1],
          });
        });
        await this.$emit("emit", {});
        this.pasteData = "";
      }
    },
  },
  methods: {
    catchEmit(e) {
      this.items = e.items
        .filter((item, index, arr) => {
          return (
            arr.findIndex((el) => {
              return el._id == item._id;
            }) == index
          );
        })
        .map((item) => {
          let obj = {};
          if (item.collection == "users") obj.customer = item._id;
          if (item.collection == "users_contacts") obj.contact = item._id;
          return obj;
        });
    },
    importCustomers() {
      this.record["campaignreceivers"] = this.items;
      this.$emit("emit", {});
      this.open = false;
    },
    async getQueries() {
      if (this.record.type) {
        this.queries = await service("queries", {
          action: "find",
          data: [
            {
              recordtype: "report",
              "setup.collection": {
                $in: [
                  this.record.type._id == "contact"
                    ? "users_contacts"
                    : "users",
                ],
              },
            },
          ],
        });
      } else this.queries = [];
    },
  },
};
</script>