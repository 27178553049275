 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                (this.locationsFilter.includes(tab.location) ||
                  !this.locationsFilter.length) &&
                !tab.hidden
            )"
            :key="index"
          >
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>

          <v-tab-item
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                (this.locationsFilter.includes(tab.location) ||
                  !this.locationsFilter.length) &&
                !tab.hidden
            )"
            :key="index"
          >
            <v-data-table
              :headers="tab.headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              :show-select="showselected"
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
              group-by="Category"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.shippingaddress="{ item }">
           <div>{{ item.shippingaddress }}</div>  
           <div> {{ item.shipzip }}  {{item.shipcountry}}  </div>
              </template>
              
              <template v-slot:item.transaction="{ item }">
                <div>
                  <router-link
                    :to="{
                      path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                    }"
                    class="link"
                    >{{
                      item.transaction.displayname || item.transaction.name
                    }}</router-link
                  >
                  <br />
                  <small
                    v-if="
                      item.quantity ==
                      item.quantityfulfilled - item.quantitybilled
                    "
                    style="
                      background-color: #4caf50;
                      color: white;
                      padding: 2px;
                    "
                    >Fully Invoicing</small
                  >
                  <small
                    v-else
                    style="
                      background-color: #555555;
                      color: white;
                      padding: 2px;
                    "
                    >Partly Invoicing</small
                  >
                </div>
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.comment="{ item }">
                <div v-for="(wz, index) of item.childs" :key="wz._id" class="pt-2">
                  <b v-if="item.childs.length > 1">{{ wz.name }}</b>
                  <p><pre>{{ item.comment[index] }}</pre></p>
                </div>
              </template>
              <template v-slot:item.warehousememo="{ item }">
                <div v-for="(wz, index) of item.childs" :key="wz._id">
                  <b v-if="item.childs.length > 1">{{ wz.name }}: </b>
                  {{ item.warehousememo[index] }}<br />
                  <p>{{ item.packages[index].join(", ") }}</p>
                </div>
              </template>
              <template v-slot:item.shippingmethod="{ item }">
                <span>
                  <v-tooltip bottom v-if="item.difshipmethod">
                    <span
                      >Shipping Method different than on original SO! ({{
                        item.originalshippingmethod
                      }})</span
                    >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-alert</v-icon>
                    </template>
                  </v-tooltip>
                  <span v-for="(wz, index) of item.childs" :key="wz._id"
                    >{{ item.shippingmethod[index] }}<br v-if="index" /><br
                      v-if="index"
                  /></span>
                </span>
              </template>
              <template v-slot:item.billcountry="{ item }">
                <span>
                  <v-tooltip bottom v-if="item.exportcustomsclearance">
                    <span>Export Customs Clearance</span>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-alert</v-icon>
                    </template>
                  </v-tooltip>
                  {{ item.billcountry }}
                </span>
              </template>
              <template v-slot:item.terms="{ item }">
                <span>
                  <v-tooltip bottom v-if="item.waitingforpayment">
                    <span>Waiting for Payment!</span>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-alert</v-icon>
                    </template>
                  </v-tooltip>
                  {{ item.terms }}
                </span>
              </template>
              <template v-slot:item.childs="{ item }">
                <span v-for="wz of item.childs" :key="wz._id">
                  <v-checkbox
                    v-model="wz.filter"
                    :disabled="!selected.find((s) => s._id == item._id)"
                    hide-details
                    class="ma-0"
                    v-if="item.childs.length > 1"
                  >
                    <template v-slot:label>
                      <small>{{ wz.name }}</small>
                    </template>
                  </v-checkbox>
                  <span v-else
                    ><router-link
                      :to="{
                        path: `/transactions/itemfulfillment/view/${wz._id}`,
                      }"
                      class="link"
                      >{{ wz.displayname || wz.name }}</router-link
                    ></span
                  >
                </span>
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        :disabled="!selected.length"
                        @click="action"
                        block
                        tile
                        color="primary"
                        v-if="showselected"
                        >Init Invoice from Selected Orders</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-btn
                        :disabled="!selected.length"
                        @click="bulkAction"
                        block
                        tile
                        color="primary"
                        v-if="
                          showselected &&
                          tab.location == '61e14c6ae4a35a668517a8fe'
                        "
                        >Create Invoices from Selected Orders</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "warehouse" },
        { text: "Category", value: "Category" },
        { text: "Date", value: "date" },
        { text: "Sales Order", value: "transaction" },
        { text: "Customer", value: "customer" },
        { text: "Bill Country", value: "billcountry" },
        { text: "Shipping Address", value: "shippingaddress" },
        //{ text: "Zip Code", value: "shipzip" },
        //{ text: "Country", value: "shipcountry" },
        { text: "Shipping Method", value: "shippingmethod" },
        // { text: "Quantity", value: "quantity" },
        // { text: "Committed", value: "quantitycommitted" },
        // { text: "Fulfilled", value: "quantityfulfilled" },
        // { text: "OnOrder", value: "quantityonorder" },
        //{ text: "Internal Comments", value: "memo" },
        { text: "WZ (ship cost)", value: "childs" },
        { text: "Warehouse Comments", value: "warehousememo" },
        { text: "Customer Service Comments", value: "comment" },
        { text: "Tracking", value: "trackingnumber" },
        { text: "Terms", value: "terms" },
        { text: "Sales Rep", value: "salesrep" },
      ],
      headersUK: [
        //{ text: "Category", value: "Category" },
        { text: "Date", value: "date" },
        { text: "Sales Order", value: "transaction" },
        { text: "Customer", value: "customer" },
        { text: "Bill Country", value: "billcountry" },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Shipping Method", value: "shippingmethod" },
        // { text: "Quantity", value: "quantity" },
        // { text: "Committed", value: "quantitycommitted" },
        //{ text: "Fulfilled", value: "quantityfulfilled" },
        //{ text: "OnOrder", value: "quantityonorder" },
        //{ text: "Internal Comments", value: "memo" },
        { text: "WZ (ship cost)", value: "childs" },
        { text: "Warehouse Comments", value: "warehousememo" },
        { text: "Customer Service Comments", value: "comment" },
        { text: "Tracking", value: "trackingnumber" },
        { text: "Terms", value: "terms" },
        { text: "Payment Status", value: "paymentstatus" },
        { text: "Sales Rep", value: "salesrep" },
      ],
      items: [],
      itemsPL: [],
      itemsUK: [],
      itemsPEDDERSUK: [],
      rows: [-1],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      customer: null,
      customers: [],
      message: "",
      showselected: true,
      shippingcost: 0,
      locationsFilter: [],
      tabs: {},
    };
  },
  async created() {
    this.loading = true;
    this.tabs = {
      PL: {
        name: "Warehouse PL",
        location: "5e0dbaba9e33df43f0b3a495",
        items: [],
        headers: this.headers,
      },
      UK: {
        name: "Warehouse UK (Birmingham)",
        location: "5faa7485371aa739b8a08843",
        items: [],
        headers: this.headers,
        hidden: true,
      },
      PeddersUK: {
        name: "Warehouse UK",
        location: "61e14c6ae4a35a668517a8fe",
        items: [],
        headers: this.headers,
      },
      BrakesPL: {
        name: "Brakes PL",
        location: "62de6196fa2fc522dc692c7e",
        items: [],
        headers: this.headers,
      },
    };
    if (
      this.$store.state.user &&
      this.$store.state.user.role._id == "role_warehouse"
    )
      this.showselected = false;
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);

    await this.getData();
    this.items.forEach((line) => {
      line.customer.displayname =
        line.customer.displayname || line.customer.name;
      this.customers.push(line.customer);
    });
    this.loading = false;
  },
  watch: {
    // selected() {
    //   this.shippingcost += select.transaction.shippingcost || 0;
    // },
  },
  methods: {
    rowClass(item) {
      if (
        ((item.paymentstatusid && item.paymentstatusid != "fullypaid") ||
          !item.paymentstatusid) &&
        item.terms == "Prepaid"
      ) {
        return "unpaid";
      }
    },
    async getData() {
      this.items = [];
      this.tabs["PL"].items = [];
      this.tabs["UK"].items = [];
      this.tabs["PeddersUK"].items = [];
      this.tabs["BrakesPL"].items = [];
      let result = await service(
        "orders",
        {
          action: "getOrdersToInvoice",
        },
        false,
        true
      );
      result.items.forEach((item) => {
        item.shippingaddress = item.shipaddressee || item.shipaddress;
      });
      result.items.forEach((item) => {
        let gtype, gtype2;
        if (["maincompany"].includes(item.company)) {
          if (item.customer.isperson) gtype = "B2C";
          else gtype = "B2B";
          if (item.billcountry == "Poland") gtype2 = "PL";
          else gtype2 = "Others";
          if (item.exportcustomsclearance) gtype2 = "Export";
          item.Category = gtype + " - " + gtype2;
          this.tabs["PL"].items.push(item);
        }
        if (
          item.company &&
          ["ozpartsuklimited"].includes(item.company) &&
          item.location &&
          !["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          )
        ) {
          this.tabs["UK"].items.push(item);
          if (item.customer.isperson) gtype = "B2C";
          else gtype = "B2B";
          if (item.exportcustomsclearance) gtype2 = "Export";
          else gtype2 = "Others";
          item.Category = gtype + " - " + gtype2;
        }
        if (
          ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          )
        ) {
          item.Category = item.source;
          this.tabs["PeddersUK"].items.push(item);
        }
        if (["brakespl"].includes(item.company)) {
          if (item.customer.isperson) gtype = "B2C";
          else gtype = "B2B";
          if (item.billcountry == "Poland") gtype2 = "PL";
          else gtype2 = "Others";
          if (item.exportcustomsclearance) gtype2 = "Export";
          item.Category = gtype + " - " + gtype2;
          this.tabs["BrakesPL"].items.push(item);
        }
      });

      //console.log(this.itemsPL);
      //this.items = result.items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    async action() {
      let terms = null;
      let location = null;
      let customer = null;
      let currency = null;
      let shippingaddress = null;
      let error = false;
      let createdfrom = [];
      let filters = [];
      let hasChild = this.selected.find(
        (line) => line.childs && line.childs.length > 1
      );
      for (let select of this.selected) {
        if (shippingaddress && select.shippingaddress != shippingaddress) {
          alert("You cannot select more shipping address");
          error = true;
        }
        shippingaddress = select.shippingaddress;
        if (location && select.location != location) {
          alert("You cannot select more locations");
          error = true;
        }
        location = select.location;
        if (terms && select.terms != terms) {
          alert("You cannot select more Terms");
          error = true;
        }
        terms = select.terms;
        if (customer && select.customer._id != customer) {
          alert("You cannot select more Customer");
          error = true;
        }
        currency = select.currency;
        if (currency && select.currency != currency) {
          alert("You cannot select more Currency");
          error = true;
        }
        customer = select.customer._id;
        createdfrom.push(select._id);
        if (select.childs && hasChild) {
          let filter = select.childs
            .filter((wz) => wz.filter || select.childs.length == 1)
            .map((wz) => wz._id);
          if (filter && filter.length) {
            await service("transactions_lines", {
              action: "find",
              data: [
                {
                  transaction: { $in: filter },
                  createdfromtransaction: select._id,
                },
              ],
              limit: -1,
            }).then((res) => {
              res.forEach((line) => filters.push(line._id));
            });
          }
        }
      }
      if (!error) {
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "invoice",
            mode: "create",
            createdfrom: createdfrom,
            location: location,
            message: this.message,
            customer: this.vendor,
            shippingcost: this.shippingcost,
            filters: filters,
          },
        });
      }
    },
    async bulkAction() {
      this.loading = true;
      for (let bill of this.selected) {
        try {
          let status = await service(
            "transactions",
            {
              action: "newTransaction",
              delivery: bill._id,
              record: {
                recordtype: "invoice",
                collection: "transactions",
                created: "new",
                createdfrom: [bill._id],
              },
            },
            false,
            false
          );
          if (status) {
            this.items = this.items.filter((item) => item._id != bill._id);
            this.tabs["PL"].items = this.tabs["PL"].items.filter(
              (item) => item._id != bill._id
            );
            this.tabs["UK"].items = this.tabs["UK"].items.filter(
              (item) => item._id != bill._id
            );
            this.tabs["PeddersUK"].items = this.tabs["PeddersUK"].items.filter(
              (item) => item._id != bill._id
            );
            this.tabs["BrakesPL"].items = this.tabs["BrakesPL"].items.filter(
              (item) => item._id != bill._id
            );
          }
        } catch (error) {
          alert(error.message);
        }
      }
      //await this.getData();
      this.loading = false;
    },
  },
};
</script>
<style>
.unpaid .paymentstatus {
  color: red;
}
</style>