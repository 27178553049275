<template>
  <div>
    <v-toolbar dark color="primary" v-if="used != 'campaign'">
      <v-toolbar-title>{{ query.name || "Loading..." }}</v-toolbar-title>
      <v-spacer></v-spacer>
      
      <newrecord
        v-if="
          hasAccess(
            query && query.setup? query.setup.collection: null,
            $route.params.recordtype,
            'create'
          ) &&
          !loading &&
          query &&
          !used &&
          (!['report'].includes($route.params.recordtype) ||
            query.setup.recordtype)
        "
        :recordtype="query.setup.recordtype || $route.params.recordtype"
        :collection="query.setup.collection"
        :query="query"
      />
      <v-btn
        icon
        class="mr-2"
        @click="edit()"
        v-if="['report'].includes($route.params.recordtype)"
      >
        <v-tooltip bottom>
          <span>Edit</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">edit</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
    </v-toolbar>
    <v-progress-linear
      :indeterminate="loading"
      height="3"
      class="ma-0"
    ></v-progress-linear>
    <v-card>
      <datatable v-if="!loading" :query="query" @emit="catchEmit($event)" />
    </v-card>
    <!-- <reportgraph
      v-if="
        !loading &&
        query &&
        query.recordtype == 'report' &&
        query.setup.graph &&
        query.setup.graph.xaxis &&
        ['$month', '$year', '$quarter'].includes(query.setup.graph.dateunit)
      "
      :items.sync="items"
      :headers="headers"
      :xaxis.sync="query.setup.graph.xaxis"
      :yaxis.sync="query.setup.graph.yaxis"
      :dateunit.sync="query.setup.graph.dateunit"
      :key="query.setup.graph.dateunit"
    /> -->
    <v-snackbar v-model="msg.show" :timeout="6000" top absolute right>{{
      msg.message
    }}</v-snackbar>
    <v-card-actions class="fill-height align-end" v-if="!used">
      <v-row align="center" justify="start" style="margin: 0px -19px">
        <v-col cols="6" class="text-left">
          <newrecord
            v-if="!loading"
            :recordtype="$route.params.recordtype"
            :collection="query.setup.collection"
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import service from "../../../api/index";
import newRecord from "./newRecord";
import reportgraph from "../../../components/tools/reportgraph/View";
/* eslint-disable */
export default {
  props: ["queryid", "filters", "used"],
  data() {
    return {
      loading: true,
      msg: false,
      items: [],
      headers: [],
      query: [],
      dateunit: null,
      rid:
        this.$route.params.recordtype === "query"
          ? this.$route.params.id
          : this.queryid,
    };
  },
  components: {
    newrecord: newRecord,
    reportgraph: reportgraph,
  },
  async created() {
    this.getQuery(this.rid);
  },
  watch: {
    "$route.params": function (params) {
      this.rid = this.queryid || params.id || params.recordtype;
      console.log(params, this.rid);
      this.getQuery(this.rid);
      //console.log("zmiana rid", this.rid);
    },
  },
  methods: {
    hasAccess(collection, recordtype, action) {
      if (
        collection &&
        action &&
        this.$store.state.user.role &&
        this.$store.state.user.role.permission
      ) {
        if (this.$store.state.user.role.permission[collection]) {
          if (this.$store.state.user.role.permission[collection][recordtype]) {
            if (
              this.$store.state.user.role.permission[collection][recordtype] &&
              Array.isArray(
                this.$store.state.user.role.permission[collection][recordtype]
              )
            ) {
              return this.$store.state.user.role.permission[collection][
                recordtype
              ].includes(action);
            }
          } else {
            if (
              this.$store.state.user.role.permission[collection] &&
              Array.isArray(this.$store.state.user.role.permission[collection])
            )
              return this.$store.state.user.role.permission[
                collection
              ].includes(action);
          }
        } else return true;
      }
      if (
        this.$store.state.user.location &&
        !this.$store.state.user.viewonly &&
        (this.record && this.record.salesrep
          ? (this.record.salesrep._id || this.record.salesrep) !=
            this.$store.state.user._id
          : false)
      ) {
        let availablelocations = [
          undefined,
          null,
          false,
          this.$store.state.user.location,
          ...(this.$store.state.user.forlocations || []),
        ];
        return availablelocations.includes(
          this.record && this.record.location
            ? this.record.location._id || this.record.location
            : undefined
        );
      } else return true;
    },
    edit() {
      this.$router.push({
        path: `/${this.query.collection}/${this.query.recordtype}/edit/${this.query._id}`,
      });
    },
    catchEmit(e) {
      this.loading = true;
      if (e.items) this.items = e.items;
      if (e.headers) this.headers = e.headers;
      if (e.dateunit && this.query.setup.graph)
        this.query.setup.graph.dateunit = e.dateunit;
      this.$emit("emit", {
        items: e.items,
        headers: e.headers,
        field: e.field,
        value: e.record,
      });
      this.loading = false;
    },

    getQuery(rid = "query") {
      if (rid) {
        this.loading = true;
        const collection = "queries";
        const data = {
          action: "getQuery",
          rid: rid,
        };
        service(collection, data)
          .catch((error) => {
            throw error;
          })
          .then((response) => {
            this.query = response;
            this.query.setup.used = this.used; // dodanie parametru gdzie zostało użyte
            for (let [key, value] of Object.entries(this.filters || {})) {
              if (this.query.setup && this.query.setup.filters)
                this.query.setup.filters.forEach((filter) => {
                  if (filter.field == key)
                    filter.value = ["$in", "$nin"].includes(filter.operator)
                      ? [value]
                      : value;
                });
            }
            //filtr per location
            let availablelocations = [
              undefined,
              null,
              false,
              this.$store.state.user.location,
              ...(this.$store.state.user.forlocations || []),
            ];
            if (
              this.$store.state.user.location &&
              this.query.recordtype == "report" &&
              ["transactions", "deliveries"].includes(
                this.query.setup.collection
              )
            ) {
              let status = false;
              if (this.query.setup && this.query.setup.filters)
                this.query.setup.filters.forEach((filter) => {
                  if (
                    filter.field == "location" &&
                    ["$in", "$eq"].includes(filter.operator)
                  ) {
                    if (!Array.isArray(filter.value))
                      filter.value = [filter.value];
                    let critery = filter.value.filter((v) =>
                      availablelocations.includes(v)
                    );
                    filter.value = critery.length
                      ? critery
                      : availablelocations;
                    filter.operator = "$in";
                    //filter.show = false;
                    status = true;
                  }
                });
              if (!status) {
                this.query.setup.filters.push({
                  field: "location",
                  operator: "$in",
                  value: availablelocations,
                });
              }
            }
            this.loading = false;
          })
          .catch((error) => {
            this.msg = { message: `${error.message}`, show: true };
            this.loading = false;
            this.$route.push({ path: "/" });
          });
      } else this.loading = false;
    },
  },
};
</script>
