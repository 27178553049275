var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px","readonly":(_vm.$route.params.mode == 'view' ||
      this.formSettings.disabled ||
      this.fieldSettings.disabled == true ||
      _vm.record.archived) &&
    !this.formSettings.show,"disabled":(_vm.$route.params.mode == 'view' ||
      this.formSettings.disabled ||
      this.fieldSettings.disabled == true ||
      _vm.record.archived) &&
    !this.formSettings.show},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.label,"placeholder":"","append-icon":"event","rules":[_vm.rules.required],"readonly":"","outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){return _vm.emitChangedValue(true)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){return _vm.emitChangedValue()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }