<template>
  <div v-if="record.shippingmethod" :key="record.shippingmethod._id">
    <inpost :record="record" />
    <dhl :record="record" />
    <dpd :record="record" />
    <v-container
      v-if="
        record.shippingmethod &&
        record.shippingmethod.shippingcarrier &&
        '62120ad69ff751f23995f4c3' ==
          (record.shippingmethod.shippingcarrier._id ||
            record.shippingmethod.shippingcarrier)
      "
    >
      <v-overlay :value="loading">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col>
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">local_shipping</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-text-field
                  dense
                  label="Name"
                  v-model="Shipment.ShipTo.Name"
                  maxlength="35"
                  counter
                ></v-text-field>
                <v-text-field
                  dense
                  label="Attention Name"
                  v-model="Shipment.ShipTo.AttentionName"
                  maxlength="35"
                  counter
                ></v-text-field>
                <!-- <v-list-item-title>{{
                Shipment.ShipTo.Name
              }}</v-list-item-title> -->

                <v-text-field
                  dense
                  label="Address Line #1"
                  v-model="Shipment.ShipTo.Address.AddressLine[0]"
                  maxlength="35"
                  counter
                ></v-text-field>

                <v-text-field
                  dense
                  label="Address Line #2"
                  v-model="Shipment.ShipTo.Address.AddressLine[1]"
                  maxlength="35"
                  counter
                ></v-text-field>

                <!-- <v-list-item-title>{{
                Shipment.ShipTo.Address.AddressLine
              }}</v-list-item-title> -->
                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      label="City"
                      v-model="Shipment.ShipTo.Address.City"
                      maxlength="30"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <!-- <v-list-item-title>{{
                Shipment.ShipTo.Address.City
              }}</v-list-item-title> -->
                    <v-text-field
                      dense
                      label="Postal Code"
                      v-model="Shipment.ShipTo.Address.PostalCode"
                      maxlength="9"
                      counter
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  v-if="
                    this.record.shipcountry &&
                    ['IE', 'US', 'CA'].includes(this.record.shipcountry._id)
                  "
                  dense
                  label="State Province Code"
                  v-model="Shipment.ShipTo.Address.StateProvinceCode"
                ></v-text-field>

                <!-- <v-list-item-title>{{
                Shipment.ShipTo.Address.PostalCode
              }}</v-list-item-title> -->
                <v-text-field
                  dense
                  label="Country Code"
                  v-model="Shipment.ShipTo.Address.CountryCode"
                ></v-text-field>
                <!-- <v-list-item-title>{{
                Shipment.ShipTo.Address.CountryCode
              }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary"
                  >mdi-account-card-details-outline</v-icon
                >
              </v-list-item-action>

              <v-list-item-content>
                <v-select
                  v-model="Account"
                  :items="AccountNumbers"
                  item-text="name"
                  item-value="_id"
                  label="Account Number"
                  placeholder
                  outlined
                  return-object
                  hide-details
                  dense
                ></v-select>
                <!-- <v-list-item-title>{{ AccountNumber.number }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary"
                  >mdi-account-card-details-outline</v-icon
                >
              </v-list-item-action>

              <v-list-item-content>
                <v-select
                  v-model="ShipmentCharge"
                  :items="ShipmentCharges"
                  item-text="name"
                  item-value="Code"
                  label="Duty and Tax"
                  placeholder
                  outlined
                  hide-details
                  dense
                ></v-select>
                <!-- <v-list-item-title>{{ AccountNumber.number }}</v-list-item-title> -->
                <v-text-field
                  v-if="ShipmentCharge == '02'"
                  dense
                  label="Account Number"
                  v-model="ReceiverAccountNumber"
                  maxlength="30"
                  counter
                  class="mt-3"
                ></v-text-field>
                <v-text-field
                  v-if="ShipmentCharge == '02'"
                  dense
                  label="Zip Code"
                  v-model="ReceiverPostalCode"
                  maxlength="30"
                  counter
                ></v-text-field>
                <v-text-field
                  v-if="ShipmentCharge == '02'"
                  dense
                  label="Country Code"
                  v-model="ReceiverCountryCode"
                  maxlength="2"
                  counter
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-truck-fast</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>Service:</v-list-item-subtitle> -->
                <v-select
                  v-model="Service"
                  :items="services"
                  item-text="Description"
                  item-value="Code"
                  label="Service"
                  placeholder
                  outlined
                  hide-details
                  dense
                ></v-select>
                <!-- <v-list-item-title>{{ Service.Description }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-package-variant-closed</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle>Packaging Type:</v-list-item-subtitle>
                <v-list-item-title>{{
                  PackagingType.Description
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-barcode-scan</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>Label Format:</v-list-item-subtitle> -->
                <v-select
                  v-model="LabelImageFormat"
                  :items="LabelImageFormats"
                  item-text="Description"
                  item-value="Code"
                  label="Label Format"
                  placeholder
                  return-object
                  outlined
                  hide-details
                  dense
                ></v-select>
                <!-- <v-list-item-title>{{
                LabelImageFormat.Description
              }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-weight-kilogram</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle>Weight (Packages):</v-list-item-subtitle>
                <v-list-item-title>
                  {{
                    this.record.trackingnumbers.reduce((total, tracking) => {
                      return (total += parseInt(tracking.weight));
                    }, 0)
                  }}
                  kg
                  {{
                    this.record.trackingnumbers.length > 1
                      ? `(${this.record.trackingnumbers.length})`
                      : ""
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="record.codamount">
              <v-list-item-action>
                <v-icon color="primary">mdi-cash-multiple</v-icon>
              </v-list-item-action>

              <v-list-item-content v-if="record.recordtype != 'salesorder'">
                <v-list-item-subtitle>COD:</v-list-item-subtitle>
                <v-list-item-title>{{ record.codamount }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-text-field
                  dense
                  error
                  label="COD"
                  v-model="record.codamount"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-row
              ><v-col class="py-0">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary"
                      >mdi-message-reply-text-outline</v-icon
                    >
                  </v-list-item-action>

                  <v-list-item-content>
                    <!-- <v-list-item-subtitle>Reference Number:</v-list-item-subtitle> -->
                    <v-text-field
                      label="Reference Number"
                      dense
                      v-model="ReferenceNumber"
                      hide-details
                    ></v-text-field>
                    <!-- <v-list-item-title>{{ AccountNumber }}</v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item> </v-col
              ><v-col class="py-0">
                <v-list-item>
                  <v-list-item-content>
                    <!-- <v-list-item-subtitle>Shipment Description:</v-list-item-subtitle> -->
                    <v-text-field
                      label="Shipment Description"
                      dense
                      v-model="Description"
                      hide-details
                    ></v-text-field>
                    <!-- <v-list-item-title>{{ AccountNumber }}</v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
              </v-col></v-row
            >

            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">phone</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>Phone:</v-list-item-subtitle> -->
                <v-text-field
                  dense
                  label="Phone"
                  v-model="Shipment.ShipTo.Phone.Number"
                  maxlength="15"
                  counter
                ></v-text-field>
                <!-- <v-list-item-title>{{
                Shipment.ShipTo.Phone.Number
              }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mail</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>E-Mail:</v-list-item-subtitle> -->
                <v-text-field
                  dense
                  label="Email"
                  v-model="Shipment.ShipTo.EMailAddress"
                  maxlength="50"
                  counter
                ></v-text-field>
                <!-- <v-list-item-title>{{
                Shipment.ShipTo.EMailAddress
              }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mail</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>E-Mail:</v-list-item-subtitle> -->
                <v-text-field
                  dense
                  label="Ship Notification"
                  v-model="InTransitNotificationEmail"
                  maxlength="50"
                  counter
                ></v-text-field>

                <!-- <v-list-item-title>{{
                Shipment.ShipTo.EMailAddress
              }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="record.customerdropship">
              <v-list-item-action>
                <v-icon color="primary">mail</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>E-Mail:</v-list-item-subtitle> -->
                <v-text-field
                  dense
                  label="Delivery Notification"
                  v-model="DeliveryNotificationEmail"
                  maxlength="50"
                  counter
                ></v-text-field>
                <v-textarea
                  v-if="DeliveryNotification"
                  v-model="DeliveryNotification"
                  label="Notification Text"
                  outlined
                  dense
                  rows="6"
                ></v-textarea>
                <!-- <v-list-item-title>{{
                Shipment.ShipTo.EMailAddress
              }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-alert type="warning" v-if="warning">{{ warning }}</v-alert>
      <v-row>
        <v-col>
          <v-btn @click="estimate" block tile color="primary"
            >Estimate Shipment</v-btn
          >
        </v-col>
        <v-col>
          <form>
            <v-file-input
              :loading="uploadstatus"
              :disabled="!!uploadedFiles.length"
              v-model="files"
              outlined
              dense
              :show-size="1000"
              label="Attach a file"
              placeholder="Select your file"
              :prepend-icon="!files.length ? 'mdi-paperclip' : ''"
              :append-outer-icon="!files.length ? '' : 'mdi-tray-arrow-up'"
              counter
              @click:append-outer="uploadFile"
              multiple
              accept="application/pdf"
            ></v-file-input>
          </form>
        </v-col>
        <v-col>
          <v-btn
            @click="shipment"
            block
            tile
            color="primary"
            :disabled="shipped || confirm"
            >Arrange Shipment</v-btn
          >
          <v-alert
            dense
            border="left"
            type="warning"
            v-model="confirm"
            transition="scale-transition"
            dismissible
          >
            <small
              >Please check custom product instruction before you arrange
              shipment</small
            >
          </v-alert>
        </v-col>
        <v-col>
          <v-btn
            @click="getLabel(LabelImageFormat)"
            :disabled="!record.trackingnumbers.length"
            block
            tile
            color="primary"
            :loading="loading"
            >Get Label</v-btn
          >
          <a v-if="labelurl" :href="labelurl" target="_blank">Show Label</a>
        </v-col>
      </v-row>
    </v-container>
    <div class="mx-8">
      <v-container>
        <v-row
          v-if="record.recordtype == 'salesorder' && record.trackingnumbers"
        >
          <v-col>
            <v-text-field
              dense
              type="number"
              outlined
              label="Length"
              v-model="record.trackingnumbers[0].boxlength"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              dense
              type="number"
              outlined
              label="Width"
              v-model="record.trackingnumbers[0].boxwidth"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              dense
              type="number"
              outlined
              label="Height"
              v-model="record.trackingnumbers[0].boxheight"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              dense
              type="number"
              outlined
              label="Weight"
              v-model="record.trackingnumbers[0].weight"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import getRecord from "../../../common/methods/getRecord";
import inpost from "../inpost/View.vue";
import dhl from "../dhl/View.vue";
import dpd from "../dpd/View.vue";
export default {
  props: ["record"],
  data() {
    return {
      confirm: false,
      uploadstatus: false,
      loading: false,
      labelurl: null,
      files: [],
      uploadedFiles: [],
      AccountNumbers: [],
      ReceiverAccountNumber: "",
      ReceiverPostalCode: "",
      ReceiverCountryCode: "",
      InTransitNotificationEmail: this.record.shipemail,
      DeliveryNotificationEmail: this.record.customerdropship
        ? this.record.customer.upsnotifyemail
        : "",
      DeliveryNotification: this.record.customerdropship
        ? this.record.customer.upsnotify
        : "",
      Account: {},
      warning: "",
      ReferenceNumber: "",
      Description: "Auto parts",
      Service: "11", //{ Code: "11", Description: "UPS Standard" },
      services: [
        { Code: "11", Description: "UPS Standard" },
        { Code: "65", Description: "UPS Express Saver" },
        { Code: "08", Description: "UPS Expedited" },
      ],
      ShipmentCharges: [
        { name: "Charge the Customer", Code: "01" },
        { name: "Charge the Customer Account", Code: "02" },
      ],
      ShipmentCharge: "01",
      PackagingType: { Code: "02", Description: "Package (max: 25kg)" },
      PackagingTypes: [{ Code: "02", Description: "Package (max: 25kg)" }],
      LabelImageFormat:
        this.record.recordtype != "salesorder"
          ? {
              Code: this.record.codamount ? "GIF" : "ZPL",
              Description: "ZPL",
            }
          : { Code: "GIF", Description: "GIF" },
      LabelImageFormats: [
        { Code: "GIF", Description: "GIF" },
        {
          Code: this.record.codamount ? "GIF" : "ZPL",
          Description: "ZPL",
        },
        // { Code: "EPL", Description: "EPL" },
        // { Code: "SPL", Description: "SPL" },
      ],
      headers: {
        "Content-Type": "application/json",
        AccessLicenseNumber: `7D8B62CC2C146792`, //`CDB15946C1F2EC3D`
      },
    };
  },
  watch: {
    async "record.shippingmethod"(v) {
      if (v) {
        this.Service = this.record.shippingmethod.carrierservice || "11";
        this.AccountNumbers = await service("lists", {
          action: "find",
          data: [
            {
              shippingcarrieraccountnumber: { $exists: true },
            },
          ],
        });
        this.Account = await service("lists", {
          action: "findOne",
          data: [
            {
              _id: this.record.shippingmethod.shippingcarrier
                ? this.record.shippingmethod.shippingcarrier._id ||
                  this.record.shippingmethod.shippingcarrier
                : this.record.shippingmethod.shippingcarrier,
            },
          ],
        });
      }
    },
    "record.customer"(v) {
      //Dropship
      this.DeliveryNotificationEmail = this.record.customerdropship
        ? this.record.customer.upsnotifyemail
        : "";
      this.DeliveryNotification = this.record.customerdropship
        ? this.record.customer.upsnotify
        : "";
    },
    Account(value) {
      this.headers.AccessLicenseNumber = value.license;
      this.headers.Username = value.email;
      this.headers.Password = value.password;
    },
  },
  async created() {
    this.record.location = await getRecord(
      this,
      this.record.location._id,
      this.record.location.collection,
      this.record.location.recordtype
    );
    if (
      this.record.shippingmethod &&
      this.record.shippingmethod.shippingcarrier
    ) {
      this.Service = this.record.shippingmethod.carrierservice || "11";
      this.AccountNumbers = await service("lists", {
        action: "find",
        data: [
          {
            shippingcarrieraccountnumber: { $exists: true },
          },
        ],
      });
      this.Account = await service("lists", {
        action: "findOne",
        data: [
          {
            _id: this.record.shippingmethod.shippingcarrier
              ? this.record.shippingmethod.shippingcarrier._id ||
                this.record.shippingmethod.shippingcarrier
              : this.record.shippingmethod.shippingcarrier,
          },
        ],
      });
      if (this.record.customer) {
        this.ReceiverAccountNumber = this.record.customer.upsaccountnumber;
        if (this.ReceiverAccountNumber) this.ShipmentCharge = "02";
        this.ReceiverPostalCode = (
          this.record.customer.billzip || ""
        ).replaceAll(" ", "");
        this.ReceiverCountryCode = this.record.customer.billcountry
          ? this.record.customer.billcountry._id ||
            this.record.customer.billcountry
          : this.record.customer.billcountry;
      }

      this.headers.AccessLicenseNumber = this.Account.license;
      this.headers.Username = this.Account.email;
      this.headers.Password = this.Account.password;
    }
    this.ReferenceNumber = this.record.name;

    this.confirm = this.record.transactionlines.some((l) => l.memo);

    // na potrzeby generowania listów bez towaruw na WZ
    if (
      this.record.recordtype == "salesorder" &&
      (!this.record.trackingnumbers ||
        (this.record.trackingnumbers && !this.record.trackingnumbers.length))
    ) {
      this.record.trackingnumbers = [
        { boxlength: 1, boxheight: 1, boxwidth: 1, weight: 0 },
      ];
    }

    if (
      this.record.recordtype == "salesorder" &&
      this.record.shippingmethod &&
      this.record.shippingmethod.codrequired
    ) {
      this.record.codamount = this.record.grossamount;
    }
  },
  mounted() {
    //window.addEventListener("message", this.receiveMessage);
  },
  computed: {
    shipped: function () {
      return (
        !this.record.trackingnumbers.length ||
        this.record.trackingnumbers.some((tracking) => {
          return tracking.name;
        })
      );
    },
    Shipment: function () {
      return {
        ReferenceNumber: {
          Value: this.ReferenceNumber,
        },
        Description: this.Description,
        ShipmentRatingOptions: {
          // UserLevelDiscountIndicator: "TRUE",
          NegotiatedRatesIndicator: "TRUE",
        },
        ShipmentServiceOptions: {},
        Shipper: {
          Name: this.record.location.shippingaddress.name,
          AttentionName: this.record.location.shippingaddress.name,
          ShipperNumber: this.Account.shippingcarrieraccountnumber,
          Phone: {
            Number: (
              this.record.location.shippingaddress.phone || ""
            ).replaceAll(" ", ""),
          },
          Address: {
            AddressLine: `${this.record.location.shippingaddress.address} ${
              this.record.location.shippingaddress.address2 || ""
            }`,
            City: this.record.location.shippingaddress.city,
            PostalCode: (
              this.record.location.shippingaddress.zip || ""
            ).replaceAll(" ", ""),
            CountryCode: this.record.location.shippingaddress.country,
          },
        },
        ShipTo: {
          Name: this.record.shipaddressee || this.record.shipname,
          AttentionName: this.record.shipaddressee || this.record.shipname,
          Phone: { Number: (this.record.shipphone || "").replaceAll(" ", "") },
          EMailAddress: this.record.email
            ? this.record.email
            : this.record.customer
            ? this.record.customer.email
            : "",
          Address: {
            //ResidentialAddressIndicator: "FALSE",
            AddressLine: [
              `${this.record.shipaddress || ""}`,
              `${this.record.shipaddress2 || ""}`,
            ],
            City: this.record.shipcity,
            PostalCode: (this.record.shipzip || "").replaceAll(" ", ""),
            CountryCode: this.record.shipcountry
              ? this.record.shipcountry._id
              : "",
            StateProvinceCode: "",
          },
        },
        ShipFrom: {
          Name: this.record.location.shippingaddress.name,
          AttentionName: this.record.location.shippingaddress.name,
          Address: {
            AddressLine: `${this.record.location.shippingaddress.address} ${
              this.record.location.shippingaddress.address2 || ""
            }`,
            City: this.record.location.shippingaddress.city,
            PostalCode: (
              this.record.location.shippingaddress.zip || ""
            ).replaceAll(" ", ""),
            CountryCode: this.record.location.shippingaddress.country,
          },
        },
        Service: { Code: this.Service },
        PaymentInformation: {
          ShipmentCharge: [
            {
              Type: "01",
              BillShipper: {
                AccountNumber: this.Account.shippingcarrieraccountnumber,
              },
            },
          ],
        },

        Package: [],
      };
    },
    upload: function () {
      return {
        UPSSecurity: {
          UsernameToken: {
            Username: this.Account.email,
            Password: this.Account.password,
          },
          ServiceAccessToken: {
            AccessLicenseNumber: this.Account.license,
          },
        },
        UploadRequest: {
          ShipperNumber: this.Account.shippingcarrieraccountnumber,
          Request: {
            TransactionReference: {},
          },
          UserCreatedForm: [],
        },
      };
    },
  },
  methods: {
    async getLabel(format) {
      try {
        this.loading = true;
        const request = {
          LabelRecoveryRequest: {
            LabelSpecification: {
              HTTPUserAgent: "",
              LabelImageFormat: {
                Code: format.Code,
              },
              // LabelStockSize: {
              //   Height: "6",
              //   Width: "4",
              // },
            },
            // Translate: {
            //   LanguageCode: "eng",
            //   DialectCode: "US",
            //   Code: "01",
            // },
            LabelDelivery: {
              LabelLinkIndicator: "",
              ResendEMailIndicator: "",
              EMailMessage: {
                EMailAddress: "",
              },
            },
            TrackingNumber: this.record["trackingnumbers"].map((t) => t.name),
          },
        };
        let result = await service("ups", {
          action: "ups",
          request: request,
          headers: this.headers,
          url: "https://onlinetools.ups.com/api/labels/v2403/recovery", //"https://onlinetools.ups.com/ship/v1801/shipments/labels",
        });
        this.labelurl = result.url;

        result.packages.forEach((pckg, index) => {
          this.print(pckg.shippinglabel, pckg.format, pckg.trackingnumber);
        });
      } catch (error) {
        this.warning = error.message;
        console.log(error);
      }
      this.loading = false;
    },
    async print(label, format, tracking_number) {
      console.log(format, label);
      label = label.replace(/\nf100\r/g, "");
      label = label.replace(/\nOD\r/g, "");

      if (["ZPL", "EPL", "epl2", "zpl", "epl"].includes(format)) {
        let myWindow = window.open(
          "http://127.0.0.1:4349/listPrinters?loc=pl_PL&app=www&name=labelWindow",
          "myWindow",
          "width=480, height=360"
        );

        setTimeout(function () {
          myWindow.postMessage(
            window.btoa(unescape(encodeURIComponent(label))),
            "*"
          );
        }, 1000);
        setTimeout(function () {
          myWindow.close();
        }, 5000);
      }
      if (["GIF", "gif"].includes(format)) {
        var image = new Image(640);
        image.src = "data:image/gif;base64," + label;

        let myWindow = window.open();

        if (tracking_number) {
          // Tworzymy element a do inicjowania pobierania
          var downloader = myWindow.document.createElement("a");
          downloader.href = image.src; // Ustawiamy źródło obrazu jako adres URL do pobrania
          downloader.download = `${tracking_number}.gif`; // Nazwa pliku do pobrania

          // Symulujemy kliknięcie na elemencie 'a' aby zainicjować pobieranie
          myWindow.document.body.appendChild(downloader); // Dodajemy element do dokumentu (potrzebne do inicjowania kliknięcia)
          downloader.click(); // Klikamy programowo w element 'a'

          // Usuwamy element 'a' po zakończeniu
          myWindow.document.body.removeChild(downloader);
        }

        myWindow.document.write(image.outerHTML);
        myWindow.document.close();
        myWindow.focus();
        myWindow.print();
      }
      if (["HTML", "html"].includes(format)) {
        //var image = new Image();
        //let src = "data:text/html;base64," + label;
        let myWindow = window.open();
        myWindow.document.write(label);
        myWindow.document.close();
        myWindow.focus();
        myWindow.print();
      }
    },
    async receiveMessage(event) {
      //console.log(event);
    },
    async estimate() {
      try {
        this.warning = "";
        this.loading = true;
        this.Shipment.Package = [];
        (this.record["trackingnumbers"] || []).forEach((number) => {
          let pck = {
            PackagingType: this.PackagingType,
            Packaging: this.PackagingType,
            Dimensions: {
              UnitOfMeasurement: {
                Code: "CM",
              },
              Length: `${number.boxlength || 10}` || "10",
              Width: `${number.boxwidth || 10}` || "10",
              Height: `${number.boxheight || 10}` || "10",
            },
            PackageWeight: {
              UnitOfMeasurement: {
                Code: "KGS",
              },
              Weight: `${number.weight}` || "1",
            },
          };
          this.Shipment.Package.push(pck);
        });
        if (this.record.codamount)
          this.Shipment.ShipmentServiceOptions["COD"] = {
            CODAmount: {
              MonetaryValue: `${this.record.codamount}`,
              CurrencyCode: "PLN",
            },
            CODFundsCode: "1",
          };
        let request = {
          RateRequest: {
            Shipment: this.Shipment,
          },
        };
        let result = await service("ups", {
          action: "ups",
          request: request,
          headers: this.headers,
          url: "https://onlinetools.ups.com/api/rating/v2403/rate", //"https://onlinetools.ups.com/ship/v1801/rating/Rate?locale=pl_PL",
        });
        if (result.shippingcost) {
          this.record.shippingcost = result.shippingcost;
        }
      } catch (error) {
        this.warning = error.message;
        console.log(error);
      }
      this.loading = false;
    },
    async shipment() {
      var response = confirm("Arrange Shipment - Are you sure?");
      if (response)
        try {
          if (this.ShipmentCharge && this.ShipmentCharge == "02") {
            this.Shipment.PaymentInformation.ShipmentCharge = [
              {
                Type: "01",
                BillShipper: {
                  AccountNumber: this.Account.shippingcarrieraccountnumber,
                },
              },
            ];
            this.Shipment.PaymentInformation.ShipmentCharge.push({
              Type: "02",
              BillThirdParty: {
                AccountNumber: this.ReceiverAccountNumber,
                Address: {
                  PostalCode: this.ReceiverPostalCode,
                  CountryCode: this.ReceiverCountryCode,
                },
              },
            });
          } else {
            this.Shipment.PaymentInformation.ShipmentCharge = [
              {
                Type: "01",
                BillShipper: {
                  AccountNumber: this.Account.shippingcarrieraccountnumber,
                },
              },
            ];
          }
          this.Shipment.Package = [];
          this.warning = "";
          this.loading = true;
          (this.record["trackingnumbers"] || []).forEach((number) => {
            let pck = {
              PackagingType: this.PackagingType,
              Packaging: this.PackagingType,
              Dimensions: {
                UnitOfMeasurement: {
                  Code: "CM",
                },
                Length: `${number.boxlength || 10}` || "10",
                Width: `${number.boxwidth || 10}` || "10",
                Height: `${number.boxheight || 10}` || "10",
              },
              PackageWeight: {
                UnitOfMeasurement: {
                  Code: "KGS",
                },
                Weight: `${number.weight}` || "1",
              },
            };
            this.Shipment.Package.push(pck);
          });
          //COD
          if (this.record.codamount) {
            this.Shipment.ShipmentServiceOptions["COD"] = {
              CODAmount: {
                MonetaryValue: `${this.record.codamount}`,
                CurrencyCode: "PLN",
              },
              CODFundsCode: "1",
            };
          }
          //Delivery Notification
          if (
            this.DeliveryNotificationEmail ||
            this.IntransitNotificationEmail
          ) {
            this.Shipment.ShipmentServiceOptions["Notification"] = [];

            if (this.DeliveryNotificationEmail) {
              this.Shipment.ShipmentServiceOptions["Notification"].push({
                NotificationCode: "8",
                EMail: {
                  EMailAddress: this.DeliveryNotificationEmail,
                  UndeliverableEMailAddr: "procurement@ozparts.eu",
                  //"FromEMailAddress ": "fromemail",
                  //FromName: "fromemail",
                  Memo: this.DeliveryNotification,
                },
                Locale: {
                  Language: "ENG",
                  Dialect: "US",
                },
              });
            }
            if (this.IntransitNotificationEmail) {
              this.Shipment.ShipmentServiceOptions["Notification"].push({
                NotificationCode: "6",
                EMail: {
                  EMailAddress: this.IntransitNotificationEmail,
                  UndeliverableEMailAddr: "procurement@ozparts.eu",
                  //"FromEMailAddress ": "fromemail",
                  //FromName: "fromemail",
                  //Memo: this.DeliveryNotification,
                },
                Locale: {
                  Language: "ENG",
                  Dialect: "US",
                },
              });
            }
          }

          if (this.uploadedFiles && this.uploadedFiles.length) {
            this.Shipment.ShipmentServiceOptions["InternationalForms"] = {
              FormType: "07",
              UserCreatedForm: {
                DocumentID: this.uploadedFiles,
              },
            };
          }
          let request = {
            ShipmentRequest: {
              LabelSpecification: {
                LabelImageFormat: this.LabelImageFormat,
                LabelStockSize: {
                  Height: "6",
                  Width: "4",
                },
              },
              Shipment: this.Shipment,
            },
          };
          let result = await service("ups", {
            action: "ups",
            request: request,
            headers: this.headers,
            url: "https://onlinetools.ups.com/api/shipments/v2403/ship", //"https://onlinetools.ups.com/ship/v1801/shipments?additionaladdressvalidation=city",
          });

          if (result.packages) {
            this.record.shippingcost = result.shippingcost;

            result.packages.forEach((pckg, index) => {
              this.record.trackingnumbers[index].name = pckg.trackingnumber;
              //this.print(pckg.shippinglabel, pckg.format);
            });
            await this.getLabel(this.LabelImageFormat);
          }
          if (result.cod) {
            this.print(result.cod.shippinglabel, result.cod.format, "COD");
          }
        } catch (error) {
          this.warning = error.message;
          console.log(error);
        }
      this.loading = false;
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          let base64 = reader.result.split(",");
          resolve(base64[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async uploadFile() {
      this.warning = "";

      try {
        let uploadRequest = this.upload;

        for (let file of this.files) {
          uploadRequest.UploadRequest.UserCreatedForm.push({
            UserCreatedFormFileName: file.name,
            UserCreatedFormFileFormat: "pdf",
            UserCreatedFormDocumentType: "002",
            UserCreatedFormFile: await this.readFileAsync(file),
          });
        }

        this.uploadstatus = true;
        let result = await service("ups", {
          action: "ups",
          request: uploadRequest,
          headers: { ShipperNumber: this.Account.shippingcarrieraccountnumber },
          url: "https://onlinetools.ups.com/api/paperlessdocuments/v2403/upload", //"https://filexfer.ups.com/rest/PaperlessDocumentAPI",
        }).catch((error) => {
          console.log(error);
          this.warning = error;
        });
        this.uploadedFiles = result.DocumentID;
        this.warning = result;
        this.uploadstatus = false;
      } catch (error) {
        this.warning = error.message;
        console.log(error);
      }
    },
  },
  components: {
    inpost: inpost,
    dhl: dhl,
    dpd: dpd,
  },
};
</script>