var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active2),callback:function ($$v) {_vm.active2=$$v},expression:"active2"}},[_c('v-tab',{key:1},[_c('span',[_vm._v("Sales Orders")])]),_c('v-tab',{key:2},[_c('span',[_vm._v("Customers")])]),_c('v-tab-item',{key:1},[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(
                !_vm.locationsFilter.length ||
                _vm.locationsFilter.includes('5e0dbaba9e33df43f0b3a495')
              )?_c('v-tab',{key:1},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v("PL")]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.itemsPL.length))])])],1):_vm._e(),(
                !_vm.locationsFilter.length ||
                ['61e14c6ae4a35a668517a8fe'].some(function (loc) { return _vm.locationsFilter.includes(loc); }
                )
              )?_c('v-tab',{key:2},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v("UK")]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.itemsUK.length))])])],1):_vm._e(),(
                !_vm.locationsFilter.length ||
                ['62de6196fa2fc522dc692c7e'].some(function (loc) { return _vm.locationsFilter.includes(loc); }
                )
              )?_c('v-tab',{key:3},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v("Brakes PL")]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.itemsBRAKESPL.length))])])],1):_vm._e(),(
                !_vm.locationsFilter.length ||
                _vm.locationsFilter.includes('5e0dbaba9e33df43f0b3a495')
              )?_c('v-tab-item',{key:1},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsPL,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.itemsPL}})],1)],1)],1)]},proxy:true},{key:"item.memo",fn:function(ref){
              var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"type":"text","dense":"","hide-details":""},on:{"click:clear":function($event){return _vm.action(item, 'memo')},"change":function($event){return _vm.action(item, 'memo')}},model:{value:(item.memo),callback:function ($$v) {_vm.$set(item, "memo", $$v)},expression:"item.memo"}})]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.planneddateofshipment",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planneddateofshipment ? new Date(item.planneddateofshipment) .toISOString() .substr(0, 10) : ""))])]}},{key:"item.customer",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                      path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                    }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.transaction",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                      path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                    }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.accountonhold",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}}],null,false,3324012272)})],1):_vm._e(),(
                !_vm.locationsFilter.length ||
                ['61e14c6ae4a35a668517a8fe'].some(function (loc) { return _vm.locationsFilter.includes(loc); }
                )
              )?_c('v-tab-item',{key:2},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsUK,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.itemsUK}})],1)],1)],1)]},proxy:true},{key:"item.memo",fn:function(ref){
              var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"type":"text","dense":"","hide-details":""},on:{"click:clear":function($event){return _vm.action(item, 'memo')},"change":function($event){return _vm.action(item, 'memo')}},model:{value:(item.memo),callback:function ($$v) {_vm.$set(item, "memo", $$v)},expression:"item.memo"}})]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.planneddateofshipment",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planneddateofshipment ? new Date(item.planneddateofshipment) .toISOString() .substr(0, 10) : ""))])]}},{key:"item.customer",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                      path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                    }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.transaction",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                      path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                    }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.accountonhold",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}}],null,false,2611854098)})],1):_vm._e(),(
                !_vm.locationsFilter.length ||
                ['62de6196fa2fc522dc692c7e'].some(function (loc) { return _vm.locationsFilter.includes(loc); }
                )
              )?_c('v-tab-item',{key:3},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsBRAKESPL,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.itemsBRAKESPL}})],1)],1)],1)]},proxy:true},{key:"item.memo",fn:function(ref){
              var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"type":"text","dense":"","hide-details":""},on:{"click:clear":function($event){return _vm.action(item, 'memo')},"change":function($event){return _vm.action(item, 'memo')}},model:{value:(item.memo),callback:function ($$v) {_vm.$set(item, "memo", $$v)},expression:"item.memo"}})]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.planneddateofshipment",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planneddateofshipment ? new Date(item.planneddateofshipment) .toISOString() .substr(0, 10) : ""))])]}},{key:"item.customer",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                      path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                    }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.transaction",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                      path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                    }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.accountonhold",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}}],null,false,2376318988)})],1):_vm._e()],1)],1),_c('v-tab-item',{key:2},[_c('v-data-table',{attrs:{"headers":_vm.headersCustomer,"items":_vm.itemsCustomer,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.alertdescription",fn:function(ref){
                    var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"type":"text","dense":"","hide-details":""},on:{"click:clear":function($event){return _vm.action(item, 'alertdescription')},"change":function($event){return _vm.action(item, 'alertdescription')}},model:{value:(item.alertdescription),callback:function ($$v) {_vm.$set(item, "alertdescription", $$v)},expression:"item.alertdescription"}})]}},{key:"item.date",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.alertdate",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.alertdate ? new Date(item.alertdate).toISOString().substr(0, 10) : ""))])]}},{key:"item.customer",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.transaction",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.accountonhold",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }