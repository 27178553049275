 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="simple ? simpleheaders : pedders ? peddersheaders : headers"
          :items="items"
          loading-text="Loading... Please wait"
          :loading="loading"
          item-key="_id"
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.stockcategory="{ item }">
            <v-select
              :items="stockcategories"
              v-model="item.stockcategory"
              label="Stock Category"
              item-text="name"
              item-value="_id"
              outlined
              dense
              hide-details
              class="mt-2 mb-2"
            ></v-select>
          </template>
          <template v-slot:item.stockgroup="{ item }">
            <v-select
              :items="stockgroups"
              v-model="item.stockgroup"
              label="Stock Group"
              item-text="name"
              item-value="_id"
              outlined
              dense
              hide-details
              class="mt-2 mb-2"
            ></v-select>
          </template>
          <template v-slot:item.smallsize="{ item }">
            <v-chip label :color="item.smallsize ? 'red' : 'none'" dark>{{
              item.smallsize ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.custombox="{ item }">
            <v-chip label :color="item.custombox ? 'red' : 'none'" dark>{{
              item.custombox ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.custombox2="{ item }">
            <v-chip label :color="item.custombox2 ? 'red' : 'none'" dark>{{
              item.custombox ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.manualsettings="{ item }">
            <v-chip label :color="item.manualsettings ? 'red' : 'none'" dark>{{
              item.manualsettings ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-combobox
                    :items="vendors"
                    v-model="vendor"
                    label="Vendor"
                    item-text="displayname"
                    item-value="_id"
                    outlined
                    dense
                    clearable
                    hide-details
                    multiple
                    class="mt-2 mb-2"
                    :loading="loading"
                  ></v-combobox>
                </v-col>
                <v-col>
                  <v-select
                    :items="locations"
                    v-model="location"
                    label="Location"
                    item-text="name"
                    item-value="_id"
                    class="mt-2 mb-2"
                    outlined
                    multiple
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :items="pricegroups"
                    v-model="pricegroup"
                    label="Price Groups"
                    item-text="name"
                    item-value="_id"
                    class="mt-2 mb-2"
                    outlined
                    multiple
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-switch
                    class="mt-2 mb-0"
                    v-model="eastonly"
                    label="Eastern Regions Only"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-switch
                    class="mt-2 mb-0"
                    v-model="simple"
                    label="Simple Algorithm"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-switch
                    class="mt-2 mb-0"
                    v-model="pedders"
                    label="Pedders Algorithm"
                  ></v-switch>
                  <v-select
                    v-if="pedders"
                    :items="
                      locations.filter((l) => ['UK', 'PL'].includes(l.name))
                    "
                    v-model="location2"
                    label="Alt. Location"
                    item-text="name"
                    item-value="_id"
                    class="mt-0 mb-2"
                    multiple
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
                <v-spacer></v-spacer>

                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <!-- <v-col>
                      <v-textarea
                        outlined
                        label="Out Of Stock Message for Stocked Item"
                        v-model="ousm_stockeditem"
                      ></v-textarea>
                    </v-col>
                    <v-col>
                      <v-textarea
                        outlined
                        label="Out Of Stock Message for Special Order Item"
                        v-model="ousm_specialorderitem"
                      ></v-textarea>
                    </v-col> -->
                    <v-col>
                      <v-btn
                        @click="action()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Apply</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData
                    :headers="
                      simple
                        ? simpleheaders
                        : pedders
                        ? peddersheaders
                        : headers
                    "
                    :items="items"
                  />
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Item", value: "item" },
        { text: "Sales", value: "itemsales" },
        { text: "Transactions Count", value: "salestransactions" },
        { text: "Avg. Monthly Sale", value: "avgMonthlySale" },
        { text: "Standard Deviation", value: "sd" },
        { text: "Coefficient of variation", value: "wspzmi" },
        { text: "wX_Wy", value: "wxwy" },
        { text: "XYZ", value: "xyz" },
        { text: "CUM.", value: "cum" },
        { text: "ABC", value: "abc" },
        { text: "Old Stock Group", value: "oldstockgroup" },
        { text: "ABCXYZ", value: "stockgroup" },
        { text: "Pref. Stock", value: "prefstock" },
        { text: "Stock DSC", value: "stockdsc" },
        { text: "New Stock DSC", value: "stockcategory" },
      ],
      simpleheaders: [
        { text: "Item", value: "item" },
        { text: "Sales", value: "itemsales" },
        { text: "Transactions Count", value: "salestransactions" },
        { text: "Avg. Monthly Sale", value: "avgMonthlySale" },
        { text: "Pref. Stock", value: "prefstock" },
        { text: "Stock DSC", value: "stockdsc" },
        { text: "New Stock DSC", value: "stockcategory" },
      ],
      peddersheaders: [
        { text: "Item", value: "item" },
        { text: "Sales", value: "itemsales" },
        { text: "Transactions Count", value: "salestransactions" },
        { text: "Avg. Monthly Sale", value: "avgMonthlySale" },

        { text: "Small Item", value: "smallsize" },
        { text: "No A/F", value: "custombox" },
        { text: "Pref. Stock", value: "oldprefstock" },
        { text: "Stock Group", value: "oldstockgroup" },
        { text: "Stock DSC", value: "oldstockcategory" },

        { text: "Manual Settings", value: "manualsettings" },
        { text: "New Pref. Stock", value: "newprefstock" },
        { text: "New Stock Group", value: "stockgroup" },
        { text: "New Stock DSC", value: "stockcategory" },
      ],
      items: [],
      rows: [25, 100],
      singleSelect: false,
      selected: [],
      search: "",
      vendor: [],
      vendors: [],
      location2: [],
      location: [],
      locations: [],
      stockcategories: [],
      stockcategory: null,
      stockgroups: [],
      stockgroup: null,
      pricegroup: [],
      pricegroups: [],
      ousm_stockeditem: null,
      ousm_specialorderitem: null,
      show: false,
      simple: false,
      pedders: false,
      eastonly: false,
    };
  },
  async created() {
    this.loading = true;
    this.locations = await service("accounting", {
      action: "find",
      data: [{ recordtype: "location", inactive: { $ne: true } }],
    });
    this.stockcategories = await service("classification", {
      action: "find",
      data: [{ recordtype: "stockcategory" }],
    });
    this.stockgroups = await service("classification", {
      action: "find",
      data: [{ recordtype: "stockgroup" }],
    });
    this.vendors = await service("users", {
      action: "find",
      data: [{ recordtype: "vendor" }],
      limit: -1,
    });
    this.pricegroups = await service("accounting", {
      action: "find",
      data: [{ recordtype: "pricegroup" }],
    });
    this.loading = false;
  },
  watch: {
    pedders(v) {
      this.location2 = [];
    },
    location2(v) {
      this.items = [];
      if (v && v.length) {
        this.peddersheaders = [
          { text: "Item", value: "item" },
          { text: "Sales", value: "itemsales" },
          { text: "Transactions Count", value: "salestransactions" },
          { text: "Avg. Monthly Sale", value: "avgMonthlySale" },

          { text: "Small Item", value: "smallsize" },
          { text: "No A/F", value: "custombox" },
          { text: "Pref. Stock", value: "oldprefstock" },
          { text: "Stock Group", value: "oldstockgroup" },
          { text: "Stock DSC", value: "oldstockcategory" },

          { text: "Manual Settings", value: "manualsettings" },
          { text: "New Pref. Stock", value: "newprefstock" },
          { text: "New Stock Group", value: "stockgroup" },
          { text: "New Stock DSC", value: "stockcategory" },
          { text: "Alt. Pref. Stock", value: "oldprefstock2" },
          { text: "Alt. Stock Group", value: "oldstockgroup2" },

          { text: "Alt. Manual Settings", value: "altmanualsettings" },
          { text: "New Alt. Pref. Stock", value: "newaltprefstock" },
          { text: "New Alt. Stock Group", value: "altstockgroup" },
        ];
      } else {
        this.peddersheaders = [
          { text: "Item", value: "item" },
          { text: "Sales", value: "itemsales" },
          { text: "Transactions Count", value: "salestransactions" },
          { text: "Avg. Monthly Sale", value: "avgMonthlySale" },

          { text: "Small Item", value: "smallsize" },
          { text: "No A/F", value: "custombox" },
          { text: "Pref. Stock", value: "oldprefstock" },
          { text: "Stock Group", value: "oldstockgroup" },
          { text: "Stock DSC", value: "oldstockcategory" },

          { text: "Manual Settings", value: "manualsettings" },
          { text: "New Pref. Stock", value: "newprefstock" },
          { text: "New Stock Group", value: "stockgroup" },
          { text: "New Stock DSC", value: "stockcategory" },
        ];
      }
    },
  },
  methods: {
    async action() {
      this.loading = true;
      //if (this.ousm_stockeditem && this.ousm_specialorderitem) {
      let result = await service(
        "items",
        {
          action: "updateInventoryStatus",
          alt: this.location2 && this.location2.length ? true : false,
          pedders: this.pedders ? true : false,
          items: this.items.map((item) => {
            let outofstockmessage = null;
            if ((item.stockcategory._id || item.stockcategory) == "stockeditem")
              outofstockmessage = this.ousm_stockeditem;
            if (
              (item.stockcategory._id || item.stockcategory) ==
              "specialorderitem"
            )
              outofstockmessage = this.ousm_specialorderitem;
            return {
              item: item._id,
              location: [this.location[0]],

              preferedstocklevel: item.newprefstock,
              stockgroup: item.stockgroup
                ? item.stockgroup._id || item.stockgroup
                : null,
              stockcategory: item.stockcategory
                ? item.stockcategory._id || item.stockcategory
                : null,
              outofstockmessage: outofstockmessage,

              preferedstocklevel2: item.newaltprefstock,
              stockgroup2: item.altstockgroup
                ? item.altstockgroup._id || item.altstockgroup
                : null,
            };
          }),
        },
        false,
        true
      );
      // } else {
      //   alert("Out of stock message is required");
      // }
      this.loading = false;
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "items",
        {
          action: "getABCXYZ",
          vendor: this.vendor.map((vendor) => {
            return vendor._id;
          }),
          location: this.location,
          altlocation: this.location2,
          simple: this.simple,
          pedders: this.pedders,
          pricegroup: this.pricegroup,
          eastonly: this.eastonly,
        },
        false,
        true
      );
      console.log(result);
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData,
  },
};
</script>