<template>
  <div>
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab> Item Fulfillments </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                dense
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <exportData :headers="headers" :items="items" />
            </v-col>
            <v-col>
              <v-menu
                v-model="menuMonth"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Month:"
                    placeholder
                    v-model="date"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    @change="getData()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  type="month"
                  @input="menuMonth = false"
                  @change="getData()"
                ></v-date-picker>
              </v-menu>
              <v-select
                :items="companies"
                v-model="company"
                label="Company"
                item-text="name"
                item-value="_id"
                outlined
                clearable
                hide-details
                class="mt-2 mb-2"
                dense
                @change="getData()"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                v-model="selected"
                show-select
                :items="
                  items.filter((l) =>
                    onlyErrors ? l.lastQty != l.lastQuantityonhand : true
                  )
                "
                :loading="loading"
                loading-text="Loading... Please wait"
                item-key="_id"
                :search="search"
                :footer-props="{ itemsPerPageOptions: rows }"
                :custom-filter="filter"
              >
                <template v-slot:item.transaction="{ item }">
                  <router-link
                    v-if="item._id"
                    :to="{
                      path: `/transactions/itemfulfillment/view/${item._id}`,
                    }"
                    class="link"
                    >{{ item.displayname || item.name }}</router-link
                  >
                </template>
                <template v-slot:item.customer="{ item }">
                  <router-link
                    v-if="item.customer"
                    :to="{
                      path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                    }"
                    class="link"
                    >{{
                      item.customer.displayname || item.customer.name
                    }}</router-link
                  >
                </template>
                <template v-slot:item.date="{ item }">
                  <v-menu
                    v-model="item.menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date:"
                        placeholder
                        v-model="item.date"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        @change="changeDate($event, item._id, 'date')"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.date"
                      @input="item.menu = false"
                      @change="changeDate($event, item._id, 'date')"
                    ></v-date-picker>
                  </v-menu>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
    <script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      tab: "overview",
      menu: false,
      menuMonth: false,
      loading: false,
      itemsList: [],
      onlyErrors: false,
      onlyscanned: true,
      bincontrol: false,
      headers: [
        { text: "WZ", value: "transaction" },
        { text: "Customer", value: "customer" },
        { text: "Date", value: "date" },
        { text: "Shipping Date", value: "shippingdate" },
      ],

      selected: [],
      items: [],
      items2: [],
      rows: [100],
      search: "",
      show: false,
      date: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2)
        .toISOString()
        .substr(0, 10),
      company: "maincompany",
      companies: [
        { name: "Ozparts PL", _id: "maincompany" },
        { name: "Ozparts UK", _id: "ozpartsuklimited" },
        { name: "Brakes PL", _id: "brakespl" },
      ],
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getItemFulfillments",
          company: this.company,
          date: this.date,
        },
        false,
        true
      );

      this.items = result.items;
      this.items.forEach((l) => (l.menu = false));
      this.loading = false;
    },

    filter(value, search, item) {
      return filter(value, search, item);
    },
    async changeDate(e, id, field = "date") {
      console.log(e);
      if (e) {
        await service("transactions", {
          action: "setField",
          collection: "transactions",
          rid: id,
          field: field,
          value: e,
        });
      }
    },
  },
  components: {
    exportData,
  },
};
</script>